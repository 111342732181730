export default [
  {
    path: '/laborContract-contract',
    name: 'laborContractContract',
    meta: { title: "劳动合同审核流程详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    //劳动合同审核流程
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/laborContractContract.vue'),
  },


  {
    path: '/personnelChanges-leaveManagement',
    name: 'personnelChangesLeaveManagement',
    meta: { title: "人员离职申请详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/personnelChangesLeaveManagement.vue'),
  },


  {
    path: '/personnelChanges-projectPersonnel',
    name: 'personnelChangesProjectPersonnel',
    meta: { title: "项目部人员调拨详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/personnelChangesProjectPersonnel.vue'),
  },


  {
    path: '/personnelChanges-promotionManagement',
    name: 'personnelChangesPromotionManagement',
    meta: { title: "晋升管理详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/personnelChangesPromotionManagement.vue'),
  },


  {
    path: '/ledger-socialSecurity',
    name: 'ledgerSocialSecurity',
    meta: { title: "社保台帐详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/ledgerSocialSecurity.vue'),
  },



  {
    path: '/attendanceManagement-jobWaiting',
    name: 'attendanceManagementJobWaiting',
    meta: { title: "加班申请详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/attendanceManagementJobWaiting.vue'),
  },


  {
    path: '/attendanceManagement-monthlyAttendance',
    name: '/attendanceManagementMonthlyAttendance',
    meta: { title: "月度考勤详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/attendanceManagementMonthlyAttendance.vue'),
  },

  {
    path: '/certificate-certificateBorrowing',
    name: '/certificateCertificateBorrowing',
    meta: { title: "证书借用详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/certificateCertificateBorrowing.vue'),
  },

  {
    path: '/vehicle-fee-registration',
    name: '/vehicleFeeRegistration',
    meta: { title: "车辆维护费用申请详情" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/vehicleFeeRegistration.vue'),
  },


  {
    path: '/attendanceManagement-askLeave',
    name: '/attendanceManagementAskLeave',
    meta: { title: "请假申请" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/attendanceManagementAskLeave.vue'),
  },

  {
    path: '/attendanceManagement-abnormalAppeal',
    name: '/attendanceManagementAbnormalAppeal',
    meta: { title: "考勤异常申诉" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/attendanceManagementAbnormalAppeal.vue'),
  },

  {
    path: '/appealforAttendance',
    name: '/appealforAttendance',
    meta: { title: "考勤异常申诉" }, //打卡去申述考勤异常
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/appealforAttendance.vue'),
  },

  {
    path: '/engineering-management/project-department-management/personnel-transferzzhlq',
    name: '/personnelProjectDepartment',
    meta: { title: "项目部人员调拨" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/personnelProjectDepartment.vue'),
  },


  {
    path: '/examine-regularWorker',
    name: '/examineRegularWorker',
    meta: { title: "试用期员工转正申请" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/examineRegularWorker.vue'),
  },

  {
    path: '/receive',
    name: '/receive',
    meta: { title: "劳保用品领用" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/receive.vue'),
  },

  {
    path: '/materials-ledger',
    name: '/materialsLedger',
    meta: { title: "物资(仪器)调拨" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/materialsLedger.vue'),
  },


  {
    path: '/materials-clear',
    name: '/materialsClear',
    meta: { title: "物资(仪器)清理" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/materialsClear.vue'),
  },

  {
    path: '/materials-procure',
    name: '/materialsProcure',
    meta: { title: "采购申请单" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/materialsProcure.vue'),
  },


  {
    path: '/receivingDocuments-registration',
    name: '/receivingDocumentsRegistration',
    meta: { title: "收文登记" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/receivingDocumentsRegistration.vue'),
  },

  {
    path: '/outgoingMessage-draft',
    name: '/outgoingMessageDraft',
    meta: { title: "发文拟稿" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/outgoingMessageDraft.vue'),
  },


  {
    path: '/career-center/operating/contract-management/contract-registration',
    name: '/contractRegistration',
    meta: { title: "合同登记" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/contractRegistration.vue'),
  },

  {
    path: '/vehicle-usage',
    name: '/vehicleUsage',
    meta: { title: "车辆使用登记" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/vehicleUsage.vue'),
  },

  {
    path: '/ksfAssessmentreport',
    name: '/ksfAssessmentreport',
    meta: { title: "月上报资料" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/ksfAssessmentreport.vue'),
  },


  {
    path: '/attendanceManagement-evection',
    name: '/attendanceManagementEvection',
    meta: { title: "出差申请" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/attendanceManagementEvection.vue'),
  },

  {
    path: '/recruit-demand',
    name: '/recruitDemand',
    meta: { title: "人员招聘" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/recruitDemand.vue'),
  },

  {
    path: '/meetingEscalation',
    name: '/meetingEscalation',
    meta: { title: "会议纪要完成情况" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/meetingEscalation.vue'),
  },


  {
    path: '/technicalcomponentinformation',
    name: '/technicalcomponentinformation',
    meta: { title: "项目组建资料申请表" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/technicalcomponentinformation.vue'),
  },

  {
    path: '/consulting-management-center/cost-department/daily-management/major-issues',
    name: '/departmentManagementMajorIssues',
    meta: { title: "重大问题/重大事件汇报" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/departmentManagementMajorIssues.vue'),
  },


  {
    path: '/completionfiling',
    name: '/completionfiling',
    meta: { title: "项目竣工资料归档" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/completionfiling.vue'),
  },


  {
    path: '/attendanceManagement-egressApplication',
    name: '/attendanceManagementEgressApplication',
    meta: { title: "人员外出" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/attendanceManagementEgressApplication.vue'),
  },

  {
    path: '/subscribe',
    name: '/subscribe',
    meta: { title: "印章管理" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/subscribe.vue'),
  },

  {
    path: '/amoebaLevel2summarytable',
    name: '/amoebaLevel2summarytable',
    meta: { title: "二级巴-汇总报表" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/amoebaLevel2summarytable.vue'),
  },

  {
    path: '/materials-allot',
    name: '/materialsAllot',
    meta: { title: "物资(仪器)调拨" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/materialsAllot.vue'),
  },


  {
    path: '/expenseContract',
    name: '/expenseContract',
    meta: { title: "支出合同登记" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import(/* webpackChunkName: "moduleA" */ '@/views/detailedProcess/expenseContract.vue'),
  },

  {
    path: '/news-newsPublish',
    name: '/newsNewsPublish',
    meta: { title: "新闻栏目" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/newsNewsPublish.vue'),
  },

  {
    path: '/career-center/operating/contract-management/application-performance-money',
    name: '/careerCenterMoney',
    meta: { title: "履约金申请" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/careerCenterMoney.vue'),
  },

  {
    path: '/SubmitApprovalConstruction/ground',
    name: '/SubmitApprovalConstruction',
    meta: { title: "土地" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/SubmitApprovalConstruction.vue'),
  },

  {
    path: '/career-center/filiale/business-management-ledger',
    name: '/businessManagementLedger',
    meta: { title: "分公司管理" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/businessManagementLedger.vue'),
  },

  {
    path: '/drawingreview',
    name: '/drawingreview',
    meta: { title: "图纸会审" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/drawingreview.vue'),
  },

  {
    path: '/disclosurecontent',
    name: '/disclosurecontent',
    meta: { title: "项目部技术交底申请" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/disclosurecontent.vue'),
  },

  {
    path: '/technologydataapplication',
    name: '/technologydataapplication',
    meta: { title: "项目部技术交底申请" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/technologydataapplication.vue'),
  },

  {
    path: '/detailedapproval',
    name: '/detailedapproval',
    meta: { title: "项目部技术交底申请.." },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/detailedapproval.vue'),
  },

  {
    path: '/personnelChanges-jobGrade',
    name: '/personnelJobGrade',
    meta: { title: "岗位级别调整" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/personnelJobGrade.vue'),
  },

  {
    path: '/career-center/filiale/financial-management-ledger/supervision-fee-collection-ledger',
    name: '/supervisionFeeCollectionLedger',
    meta: { title: "分公司管理..." },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/supervisionFeeCollectionLedger.vue'),
  },


  {
    path: '/career-center/filiale/engineering-management/quality-audit',
    name: '/engineeringManagement',
    meta: { title: "分公司稽查表" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/engineeringManagement.vue'),
  },

  {
    path: '/summarytable1',
    name: '/summarytable',
    meta: { title: "项目全要素管理汇总表（一）" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/summarytable.vue'),
  },

  {
    path: '/summarytable2',
    name: '/summarytableSecond',
    meta: { title: "项目全要素管理汇总表（二）" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/summarytableSecond.vue'),
  },


  {
    path: '/examine-employeeAppraisal',
    name: '/examineEmployeeAppraisal',
    meta: { title: "试用期考核" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/examineEmployeeAppraisal.vue'),
  },

  {
    path: '/consulting-management-center/cost-department/project-team-management/project-initiation',
    name: '/projectInitiation',
    meta: { title: "项目立项" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/projectInitiation.vue'),
  },

  {
    path: '/monthlyreport',
    name: '/monthlyreport',
    meta: { title: "月上报资料_人事月报" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/monthlyreport.vue'),
  },


  {
    path: '/recruit-employment',
    name: '/recruitEmployment',
    meta: { title: "人员录用" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/recruitEmployment.vue'),
  },


  {
    path: '/consulting-management-center/bidding-Department/refundBidSecurityDeposit',
    name: '/refundBidSecurityDeposit',
    meta: { title: "投标保证金退款" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/refundBidSecurityDeposit.vue'),
  },


  {
    path: '/amoebaLevel4-summarytable',
    name: '/amoebaLevelSummarytable',
    meta: { title: "四级巴预算月度表" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/amoebaLevelSummarytable.vue'),
  },


  {
    path: '/administrative-violationDetails',
    name: '/violationDetails',
    meta: { title: "违规项明细管理" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/violationDetails.vue'),
  },


  {
    path: '/meetingSubscribe',
    name: '/meetingSubscribe',
    meta: { title: "会议申请" },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/meetingSubscribe.vue'),
  },



  {
    path: '/career-center/filiale/engineering-management/safety-command',
    name: '/safetyCommand',
    meta: { title: "分公司稽查表..." },
    queryTransform: item => ({
      id: item.id,
    }),
    component: () => import('@/views/detailedProcess/safetyCommand.vue'),
  },


  {
    path: '/egress-feedback',
    name: '/egressFeedback',
    meta: { title: "人员外出反馈" },
    component: () => import('@/views/detailedProcess/egressFeedback.vue'),
  },

  {
    path: '/career-center/filiale/financial-management-ledger/cash-flow-statement',
    name: '/cashFlowStatement',
    meta: { title: "分公司管理-事业现金流" },
    component: () => import('@/views/detailedProcess/cashFlowStatement.vue'),
  },


  {
    path: '/career-center/filiale/financial-management-ledger/balance-sheet',
    name: '/cashFlowStatement',
    meta: { title: "分公司管理-事业资产负债" },
    component: () => import('@/views/detailedProcess/cashFlowStatement.vue'),
  },

  {
    path: '/personal-assistant',
    name: '/personalSsistant',
    meta: { title: "印章使用（盖章）申请" },
    component: () => import('@/views/detailedProcess/personalSsistant.vue'),
  },


  {
    path: '/standardacceptance',
    name: '/standardacceptance',
    meta: { title: "项目部组建达标验收申请" },
    component: () => import('@/views/detailedProcess/standardacceptance.vue'),
  },


  {
    path: '/consulting-management-center/project-management/construction-stage/cost-control',
    name: '/projectPlanningzzh',
    meta: { title: "待办流程-成本管理" },
    component: () => import('@/views/detailedProcess/projectPlanningzzh.vue'),
  },

  {
    path: '/consulting-management-center/project-management/construction-stage/projectPlanningzzh',
    name: '/projectManagementPlanningzzh',
    meta: { title: "已办流程-成本管理" },
    component: () => import('@/views/detailedProcess/projectManagementPlanningzzh.vue'),
  },


  {
    path: '/consulting-management-center/project-management/post-project-evaluation',
    name: '/postProjectEvaluation',
    meta: { title: "项目后评价报告" },
    component: () => import('@/views/detailedProcess/postProjectEvaluation.vue'),
  },

  {
    path: '/supervisionLog',
    name: '/SupervisionLogs',
    meta: { title: "监理日志" },
    component: () => import('@/views/detailedProcess/SupervisionLogs.vue'),
  },

  {
    path: '/majordomoRoutineLog',
    name: '/SupervisionLogs',
    meta: { title: "监理日志" },
    component: () => import('@/views/detailedProcess/SupervisionLogs.vue'),
  },


  {
    path: '/career-center/filiale/financial-management-ledger/income-statement',
    name: '/incomeStatement',
    meta: { title: "事业-分公司管理" },
    component: () => import('@/views/detailedProcess/incomeStatement.vue'),
  },


  {
    path: '/project-management/construction-stage/progress-control/panoramicPlan',
    name: '/panoramicPlan',
    meta: { title: "验收" },
    component: () => import('@/views/detailedProcess/panoramicPlan.vue'),
  },

  {
    path: '/consulting-management-center/cost-department/constructionBudgetCostManagement/workerContractPriceLimitTable',
    name: '/workerContractPriceLimitTable',
    meta: { title: "人工发包限价表审核" },
    component: () => import('@/views/detailedProcess/workerContractPriceLimitTable.vue'),
  },



  {
    path: '/consulting-management-center/cost-department/constructionBudgetCostManagement/materialAndMachineryContractPriceLimitTable',
    name: '/materialAndMachineryContractPriceLimitTable',
    meta: { title: "材料、机械发包限价表审核" },
    component: () => import('@/views/detailedProcess/materialAndMachineryContractPriceLimitTable.vue'),
  },

  {
    path: '/consulting-management-center/cost-department/constructionBudgetCostManagement/materialAndMachineryConsumptionControlTable',
    name: '/materialAndMachineryConsumptionControlTable',
    meta: { title: "材料、机械消耗量控制表审核" },
    component: () => import('@/views/detailedProcess/materialAndMachineryConsumptionControlTable.vue'),
  },

  {
    path: '/consulting-management-center/cost-department/constructionBudgetCostManagement/workerConsumptionControlTable',
    name: '/workerConsumptionControlTable',
    meta: { title: "人工消耗量控制表审核" },
    component: () => import('@/views/detailedProcess/workerConsumptionControlTable.vue'),
  },

  {
    path: '/consulting-management-center/surveyDesignDepartment/drawingReview',
    name: '/surveyDrawingReview',
    meta: { title: "图纸审核详情" },
    component: () => import('@/views/detailedProcess/surveyDrawingReview.vue'),
  },

  {
    path: '/consulting-management-center/cost-department/daily-management/consultatione-fee-declaration',
    name: '/consultationeFeeDeclaration',
    meta: { title: "咨询费用申报" },
    component: () => import('@/views/detailedProcess/consultationeFeeDeclaration.vue'),
  },


  {
    path: '/consulting-management-center/bidding-Department/tenderAnnouncement',
    name: '/tenderAnnouncement',
    meta: { title: "招标公告详情" },
    component: () => import('@/views/detailedProcess/tenderAnnouncement.vue'),
  },

  {
    path: '/material-management/apply',
    name: '/materialManagementApply',
    meta: { title: "甲供材申请" },
    component: () => import('@/views/detailedProcess/materialManagementApply.vue'),
  },

  {
    path: '/money-center/capital-management/children/Loan-application',
    name: '/LoanApplication',
    meta: { title: "借款申请" },
    component: () => import('@/views/detailedProcess/LoanApplication.vue'),
  },


  {
    path: '/workSupplies-receive',
    name: '/workSuppliesReceive',
    meta: { title: "办公用品领用" },
    component: () => import('@/views/detailedProcess/workSuppliesReceive.vue'),
  },

  {
    path: '/memorabilia',
    name: '/memorabilia',
    meta: { title: "项目大事记" },
    component: () => import('@/views/detailedProcess/memorabilia.vue'),
  },

  {
    path: '/project-management/construction-stage/progress-control/monthWeekPlan',
    name: '/monthWeekPlan',
    meta: { title: "月周进度计划" },
    component: () => import('@/views/detailedProcess/monthWeekPlan.vue'),
  },

  {
    path: '/project-management/construction-stage/progress-control/procurementPlan',
    name: '/procurementPlan',
    meta: { title: "招采计划详情" },
    component: () => import('@/views/detailedProcess/procurementPlan.vue'),
  },


  {
    path: '/consulting-management-center/project-management/construction-stage/investmentControlzzh',
    name: '/investmentControlzzh',
    meta: { title: "投资控制" },
    component: () => import('@/views/detailedProcess/investmentControlzzh.vue'),
  },


  {
    path: '/project-management/construction-stage/engineering-inspections/inspectionNoticeAndResponse',
    name: '/inspectionNoticeAndResponse',
    meta: { title: "项目巡查详情" },
    component: () => import('@/views/detailedProcess/inspectionNoticeAndResponse.vue'),
  },


  {
    path: '/consulting-management-center/project-management/project-dynamics/projectMilestones',
    name: '/projectMilestones',
    meta: { title: "项目大事记..." },
    component: () => import('@/views/detailedProcess/memorabilia.vue'),
  },


  {
    path: '/project-management/construction-stage/engineering-inspections/inspectionPlanApproval',
    name: '/inspectionPlanApproval',
    meta: { title: "工程巡查计划" },
    component: () => import('@/views/detailedProcess/inspectionPlanApproval.vue'),
  },


  {
    path: '/consulting-management-center/project-management/construction-stage/safety-management',
    name: '/safetyManagement',
    meta: { title: "安全管理详情" },
    component: () => import('@/views/detailedProcess/inspectionNoticeAndResponse.vue'),
  },


  {
    path: '/consulting-management-center/project-management/construction-stage/drawingConstructionzzh',
    name: '/drawingConstructionzzh',
    meta: { title: "图纸会审/施工图样板" },
    component: () => import('@/views/detailedProcess/drawingConstructionzzh.vue'),
  },


  {
    path: '/consulting-management-center/project-management/construction-stage/formalWorkzzh',
    name: '/formalWorkzzh',
    meta: { title: "正式开工" },
    component: () => import('@/views/detailedProcess/formalWorkzzh.vue'),
  },

  {
    path: '/ProjectDepartmentPlan',
    name: '/ProjectDepartmentPlan',
    meta: { title: "项目部计划" },
    component: () => import('@/views/detailedProcess/ProjectDepartmentPlan.vue'),
  },

  {
    path: '/recruit-interview',
    name: '/recruitInterview',
    meta: { title: "人员录用" },
    component: () => import('@/views/detailedProcess/recruitInterview.vue'),
  },

  {
    path: '/engineering-management/project-report/project-inspection',
    name: '/projectInspection',
    meta: { title: "项目迎检详情" },
    component: () => import('@/views/detailedProcess/projectInspection.vue'),
  },

  {
    path: '/inspection-plan',
    name: '/inspectionPlan',
    meta: { title: "公司巡检计划" },
    component: () => import('@/views/detailedProcess/inspectionPlan.vue'),
  },

  {
    path: '/consulting-management-center/surveyDesignDepartment/customerFunctionalRequirements',
    name: '/customerFunctionalRequirements',
    meta: { title: "客户功能需求" },
    component: () => import('@/views/detailedProcess/customerFunctionalRequirements.vue'),
  },


  {
    path: '/sealApprovalView',
    name: '/sealApprovalView',
    meta: { title: "印章审批详情" },
    component: () => import('@/views/detailedProcess/sealApprovalView.vue'),
  },

  {
    path: '/HYcheckWorkAttendance',
    name: '/HYcheckWorkAttendance',
    meta: { title: "月度考勤汇总签批" },
    component: () => import('@/views/detailedProcess/HYcheckWorkAttendance.vue'),
  },

  {
    path: '/officeAttendance',
    name: '/officeAttendance',
    meta: { title: "月度考勤汇总签批" },
    component: () => import('@/views/detailedProcess/officeAttendance.vue'),
  },

  {
    path: '/proxyIssuance',
    name: '/proxyIssuance',
    meta: { title: "月度考勤汇总签批" },
    component: () => import('@/views/detailedProcess/officeAttendance.vue'),
  },

  {
    path: '/projectAttendance',
    name: '/projectAttendance',
    meta: { title: "月度考勤汇总签批" },
    component: () => import('@/views/detailedProcess/officeAttendance.vue'),
  },
  
];

//1======const receive = (resolve) =>require(["@/views/processManagement/children/receive.vue"], resolve); //社保详情
//2======laborContract-contract    劳动合同审核流程
//3======personnelChanges-leaveManagement   离职管理
//4======personnelChanges-projectPersonnel  项目部人员调拨
//5======personnelChanges-promotionManagement 晋升管理
//6======attendanceManagement-jobWaiting  加班申请详情
//7======attendanceManagement-monthlyAttendance  月度考勤详情
//8======certificate-certificateBorrowing  证书借用 
//9======vehicle-fee-registration 车辆维护费用申请
//10=====attendanceManagement-askLeave   请假申请
//11=====attendanceManagement-abnormalAppeal   考勤异常申诉
//12=====personnelProjectDepartment  项目部人员调拨
//14=====examine-regularWorker   试用期员工转正申请
//15=====receive 劳保用品领用
//16=====materials-ledger  物资(仪器)调拨
//17=====materials-clear   物资(仪器)清理(2)
//18=====materials-procure   采购申请单
//19=====receivingDocuments-registration 收文登记
//20=====outgoingMessage-draft  发文拟稿
//21=====career-center/operating/contract-management/contract-registration   合同登记
//22=====vehicle-usage 车辆使用登记
//23=====ksfAssessmentreport
//24=====attendanceManagement-evection  出差申请
//25=====recruit-demand //人员招聘
//26=====meetingEscalation 会议纪要完成情况
//28=====technicalcomponentinformation  项目组建资料申请表
//29=====consulting-management-center/cost-department/daily-management/major-issues   重大问题/重大事件汇报
//30=====completionfiling  项目竣工资料归档
//31=====attendanceManagement-egressApplication 人员外出
//32=====subscribe 印章管理
//33=====amoebaLevel2summarytable 二级巴-汇总报表
//34=====materials-allot 物资(仪器)调拨2
//35=====expenseContract 支出合同登记
//36=====news-newsPublish 新闻栏目
//37=====career-center/operating/contract-management/application-performance-money  履约金申请审核流程
//38=====SubmitApprovalConstruction/ground 土地
//39=====career-center/filiale/business-management-ledger  分公司管理
//40=====drawingreview 图纸会审
//41=====disclosurecontent  项目部技术交底申请
//42=====technologydataapplication 项目技术资料申请表
//43=====detailedapproval 项目技术资料申请表2
//44=====personnelChanges-jobGrade 岗位级别调整
//45=====career-center/filiale/financial-management-ledger/supervision-fee-collection-ledger 分公司管理2 
//46=====career-center/filiale/engineering-management/quality-audit 分公司稽查表
//47=====summarytable1 项目全要素管理汇总表（一）
//48=====summarytable2 项目全要素管理汇总表（二）
//49=====examine-employeeAppraisal 试用期考核
//50=====consulting-management-center/cost-department/project-team-management/project-initiation 项目立项
//51=====monthlyreport 月上报资料_人事月报
//52=====recruit-employment 人员录用
//53===== consulting-management-center/bidding-Department/refundBidSecurityDeposit 投标保证金退款
//54=====amoebaLevel4-summarytable  四级巴预算月度表
//55=====administrative-violationDetails   违规项明细管理
//56=====meetingSubscribe 会议申请
//57=====career-center/filiale/engineering-management/safety-command  分公司稽查表...
//58=====egress-feedback 人员外出反馈
//59=====career-center/filiale/financial-management-ledger/cash-flow-statement 分公司管理-事业现金流
//60=====career-center/filiale/financial-management-ledger/balance-sheet 分公司管理-事业资产负债
//61=====personal-assistant 印章使用（盖章）申请
//62=====standardacceptance  项目部组建达标验收申请
//63=====consulting-management-center/project-management/construction-stage/cost-control 成本管理
//64=====consulting-management-center/project-management/construction-stage/projectPlanningzzh 成本管理...
//65=====consulting-management-center/project-management/post-project-evaluation  项目后评价报告
//66=====supervisionLog 监理日志
//67=====majordomoRoutineLog 监理日志....
//68=====career-center/filiale/financial-management-ledger/income-statement 分公司管理
//69=====project-management/construction-stage/progress-control/panoramicPlan 验收
//70=====consulting-management-center/cost-department/constructionBudgetCostManagement/workerContractPriceLimitTable 人工发包限价表审核
//71=====consulting-management-center/cost-department/constructionBudgetCostManagement/materialAndMachineryContractPriceLimitTable  材料、机械发包限价表审核
//72=====consulting-management-center/cost-department/constructionBudgetCostManagement/materialAndMachineryConsumptionControlTable 材料、机械消耗量控制表审核
//73=====consulting-management-center/cost-department/constructionBudgetCostManagement/workerConsumptionControlTable 人工消耗量控制表审核
//74=====consulting-management-center/surveyDesignDepartment/drawingReview  图纸审核详情
//75=====consulting-management-center/cost-department/daily-management/consultatione-fee-declaration  咨询费用申报 
//76=====consulting-management-center/bidding-Department/tenderAnnouncement  招标公告详情
//77=====material-management/apply 甲供材申请
//78=====//money-center/capital-management/children/Loan-application  借款申请
//79=====//workSupplies-receive   办公用品领用
//80=====memorabilia   项目大事记
//81=====project-management/construction-stage/progress-control/panoramicPlan  质量管理
//82=====project-management/construction-stage/progress-control/monthWeekPlan 月周进度计划
//83=====project-management/construction-stage/progress-control/procurementPlan 招采计划详情
//84=====consulting-management-center/project-management/construction-stage/investmentControlzzh 投资控制
//85=====project-management/construction-stage/engineering-inspections/inspectionNoticeAndResponse 项目巡查详情
//86=====consulting-management-center/project-management/project-dynamics/projectMilestones  项目大事记...
//87=====project-management/construction-stage/engineering-inspections/inspectionPlanApproval 工程巡查计划
//88=====consulting-management-center/project-management/construction-stage/safety-management 安全管理详情
//89=====//consulting-management-center/project-management/construction-stage/drawingConstructionzzh 图纸会审/施工图样板
//90=====//consulting-management-center/project-management/construction-stage/formalWorkzzh 正式开工
//91=====ProjectDepartmentPlan 项目部计划
//92=====recruit-interview 人员录用
//93=====engineering-management/project-report/project-inspection 项目迎检详情
//94=====inspection-plan 巡检计划
//95=====consulting-management-center/surveyDesignDepartment/customerFunctionalRequirements 客户功能需求
